import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import FlexRow from 'src/components/FlexRow/FlexRow';
import {
  Card,
  CardFooter,
  CardEmpty,
  Button,
  Container,
  Actions,
  Section,
  TabSegments,
  Toggle,
} from 'src/components/IMUI';
import paginate from 'src/components/IMUI/Pagination/paginate';
import Pagination from 'src/components/IMUI/Pagination/Pagination';
import history from 'src/historyInstance';
import { getText, getOrgText } from 'src/services/DictionaryService';
import DataFilter from '../DataFilter/DataFilter';
import GrantTableList from './GrantTableList';
import cls from './GrantSelector.module.css';
const cx = classNames.bind(cls);

class GrantSelector extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['grants', 'reports', 'surveys']).isRequired,
    renderFooter: PropTypes.element,
    tableValues: PropTypes.array,
    data: PropTypes.array,
    className: PropTypes.string,
    dictionaryPriority: PropTypes.oneOf(['project', 'organization']),
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onToggleSelect: PropTypes.func.isRequired,
    copyTaggings: PropTypes.bool,
    onToggleCopyTaggings: PropTypes.func,
    onSelectedRowsReset: PropTypes.func.isRequired,
    isAdded: PropTypes.func.isRequired,
    isCheck: PropTypes.func.isRequired,
    prevAdd: PropTypes.func.isRequired,

    selectedToRemoveLength: PropTypes.number,
    selectedToAddLength: PropTypes.number,
    handleRemoveSelected: PropTypes.func,
    handleAddedSelected: PropTypes.func,
  };

  static defaultProps = {
    type: 'grant',
    data: [],
    dictionaryPriority: 'project',
  };
  constructor(props) {
    super(props);
    this.state = {
      activeSegmentId: 'all',
      sortedItems: props.data,
      filteredItems: props.data,
      filteredData: props.data,
      selectedItems: props.data,
      currentPagination: { per_page: 1000, page: 0 },
      initialized: { filter: false, sort: false },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        sortedItems: nextProps.data,
        selectedItems: nextProps.data,
      });
    }
  }
  onSortChange = (sortedItems, filteredData) => {
    this.setState({
      filteredData,
      sortedItems,
      currentPagination: { ...this.state.currentPagination, page: 0 },
    });
  };
  onTabFiltersChange = (payload) => {
    const filteredData = this.state.filteredItems.filter((el) =>
      payload.includes(el)
    );
    const sortedFiltered = this.state.sortedItems.filter((el) =>
      filteredData.includes(el)
    );
    this.setState({
      selectedItems: payload,
      filteredData: sortedFiltered,
      currentPagination: { ...this.state.currentPagination, page: 0 },
    });
  };
  onFiltersChange = (payload) => {
    const filteredData = payload.filter((el) =>
      this.state.selectedItems.includes(el)
    );
    const sortedFiltered = this.state.sortedItems.filter((el) =>
      filteredData.includes(el)
    );
    if (this.state.initialized.filter) {
      this.props.onSelectedRowsReset();
    }
    this.setState({
      filteredItems: payload,
      filteredData: sortedFiltered,
      currentPagination: { ...this.state.currentPagination, page: 0 },
      initialized: { ...this.state.initialized, filter: true },
    });
  };

  onTabChange(tab) {
    this.setState({ activeSegmentId: tab });
    if (tab === 'all')
      return this.onTabFiltersChange(this.props.data.filter(Boolean));
    if (tab === 'added')
      return this.onTabFiltersChange(
        this.props.data.filter((i) => this.props.isAdded(i))
      );
    if (tab === 'notAdded')
      return this.onTabFiltersChange(
        this.props.data.filter((i) => !this.props.isAdded(i))
      );
  }

  render() {
    const { items, cursor } = paginate(
      this.state.filteredData,
      this.state.currentPagination
    );
    const translateText = (text) =>
      this.props.dictionaryPriority === 'project'
        ? getText(text)
        : getOrgText(text);
    const addLength = this.props.data.filter((i) =>
      this.props.isAdded(i)
    ).length;
    const notLength = this.props.data.filter(
      (i) => !this.props.isAdded(i)
    ).length;
    const allLength = addLength + notLength;
    const configFilters = this.props.tableValues.filter(
      (filter) => !filter.isHiddenInFilters
    );

    if (this.props.data.length === 0)
      return (
        <div className={cx('grant-selector-container')}>
          <Container horizontal style={{ flexShrink: 0 }}>
            <CardEmpty
              title="No items to display"
              description={`You will see ${this.props.type} from all projects`}
            >
              {this.props.type === 'grants' && (
                <Button
                  label={`Create ${translateText('Grant')}`}
                  secondary
                  onClick={() => history.push('/grants/add/1')}
                />
              )}
            </CardEmpty>
          </Container>
        </div>
      );

    const segments = [
      {
        id: 'all',
        active: this.state.activeSegmentId === 'all',
        text: (
          <span>
            All&nbsp;<span className={cls.lighterText}>{`(${allLength})`}</span>
          </span>
        ),
      },
      {
        id: 'added',
        active: this.state.activeSegmentId === 'added',
        text: (
          <span>
            Added&nbsp;
            <span className={cls.lighterText}>{`(${addLength})`}</span>
          </span>
        ),
      },
      {
        id: 'notAdded',
        active: this.state.activeSegmentId === 'notAdded',
        text: (
          <span>
            Not Added&nbsp;
            <span className={cls.lighterText}>{`(${notLength})`}</span>
          </span>
        ),
      },
    ];

    return (
      <div className={cx('grant-selector-container')}>
        {(this.props.type === 'reports' || this.props.type === 'surveys') && (
          <div className={cls.copyTaggingToggle}>
            <div>
              <Toggle
                label={`Include Report Taggings`}
                toggled={this.props.copyTaggings}
                onToggle={(_, toggled) =>
                  this.props.onToggleCopyTaggings(toggled)
                }
              />
            </div>
          </div>
        )}
        <Container horizontal style={{ flexShrink: 0 }}>
          <Actions>
            <h3 className={cls.titleAction}>
              <DataFilter
                showInputFilter
                className={cls.dataFilter}
                config={configFilters}
                data={this.props.data}
                onChange={this.onFiltersChange}
              />
            </h3>
          </Actions>
          <Actions>
            <TabSegments
              className={cls.spaceBottom}
              onToggle={(segmentId) => this.onTabChange(segmentId)}
              segments={segments}
            />
          </Actions>
        </Container>
        <Card>
          <FlexRow direction="vertical">
            <div className={cls.tableWrapper}>
              <GrantTableList
                compact={true}
                values={this.props.tableValues}
                dataPaginated={items}
                dataAll={this.props.data}
                filteredData={this.state.filteredData}
                isCheck={this.props.isCheck}
                isAdded={this.props.isAdded}
                prevAdd={this.props.prevAdd}
                onAdd={this.props.onAdd}
                onRemove={this.props.onRemove}
                onSortChange={this.onSortChange}
                onToggleSelect={(i, isChecked) =>
                  this.props.onToggleSelect(i, isChecked)
                }
              />
            </div>
            <CardFooter noScroll>
              <Pagination
                {...cursor}
                perPageOptions={[10, 30, 50, 100, 500, 1000]}
                items_label={'items'}
                onChange={(data) => this.setState({ currentPagination: data })}
              />
            </CardFooter>
          </FlexRow>
        </Card>

        {(this.props.selectedToRemoveLength > 0 ||
          this.props.selectedToAddLength > 0) && (
          <Section type="sticky-footer-second" secondary={true}>
            <Container horizontal>
              <Actions>
                {this.props.selectedToRemoveLength > 0 && (
                  <Button
                    alert
                    style={{ fontWeight: 600 }}
                    size="m"
                    label={`REMOVE SELECTED (${this.props.selectedToRemoveLength})`}
                    onClick={this.props.handleRemoveSelected}
                  />
                )}
              </Actions>
              <Actions>
                {this.props.selectedToAddLength > 0 && (
                  <Button
                    style={{ fontWeight: 600 }}
                    size="m"
                    label={`ADD SELECTED (${this.props.selectedToAddLength})`}
                    onClick={this.props.handleAddedSelected}
                  />
                )}
              </Actions>
            </Container>
          </Section>
        )}

        <div style={{ paddingBottom: 100 }} />
      </div>
    );
  }
}

export default GrantSelector;
