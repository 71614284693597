import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardFooter,
  Container,
  Section,
  Table,
} from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import PaginationIm from 'src/components/IMUI/Pagination/PaginationIm';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import activitiesApi from 'src/api/Activities';
import { where } from 'im/api/Query';
import { userCurrentOrgId } from 'src/userStorage';

const Activities = () => {
  const [activities, setActivities] = useState([]);
  const [paginationLinks, setPaginationLinks] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  const dispatch = useDispatch();

  const fetchActivities = useCallback(() => {
    dispatch(
      activitiesApi.findAll(
        where()
          .include('user', 'organization')
          .filter('organization_id_eq', userCurrentOrgId())
          .sort('-created_at')
          .paginate({ number: currentPage, size: perPage })
      )
    ).then((response) => {
      setActivities(response.data);
      setPaginationLinks(response.links);
    });
  }, [currentPage, dispatch, perPage]);

  useEffect(() => fetchActivities(), [fetchActivities]);
  useEffect(() => fetchActivities(), [currentPage, perPage, fetchActivities]);

  const translateAction = (action) => {
    switch (action) {
      case 'POST':
        return 'create';
      case 'PUT':
        return 'update';
      default:
        return action;
    }
  };
  const handlePaginationChange = ({ page, per_page }) => {
    setCurrentPage(page);
    setPerPage(per_page);
  };

  return (
    <Page title="User Logs">
      <div>
        <Section noBorder>
          <Card>
            <Container>
              <TableComposition>
                <Table.Head>
                  <Table.HCell text="Timestamp" />
                  <Table.HCell text="User" />
                  <Table.HCell text="Resource ID" />
                  <Table.HCell text="Resource Type" />
                  <Table.HCell text="Action" />
                </Table.Head>
                <Table.Body>
                  {activities.map((activity, i) => (
                    <Table.Row key={activity.id}>
                      <Table.Cell>
                        {format(
                          new Date(activity.created_at),
                          'MMMM dd, yyyy HH:mm:ss zzz'
                        )}
                      </Table.Cell>
                      <Table.Cell>{activity.user.email}</Table.Cell>
                      <Table.Cell>{activity.resource?.id || '-'}</Table.Cell>
                      <Table.Cell>{activity.resource?.type || '-'}</Table.Cell>
                      <Table.Cell>
                        {translateAction(activity.action)}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </TableComposition>
            </Container>
            <CardFooter>
              <PaginationIm
                {...paginationLinks.meta}
                label="logs"
                onChange={handlePaginationChange}
              />
            </CardFooter>
          </Card>
        </Section>
      </div>
    </Page>
  );
};

export default Activities;
