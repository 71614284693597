import React from 'react';
import { Table } from 'src/components/IMUI';
import TableComposition from 'src/components/IMUI/Tables/TableComposition';
import { SINGLE_CHOICE } from 'src/data/questionTypes';

const SingleMatrixAnswerTableValue = ({ question, answer }) => {
  const getValue = (row, column) => {
    return question.matrixQuestionType === SINGLE_CHOICE
      ? answer.response.object_value?.[row.title] === column.title
        ? 1
        : '-'
      : answer.response.object_value?.[`${row.title}_${column.id}`] || '-';
  };

  return (
    <TableComposition compact>
      <Table.Head>
        <Table.HCell />
        {question.settings.columns.map((column) => (
          <Table.HCell key={column.id} text={column.title} textAlign="center" />
        ))}
      </Table.Head>
      <Table.Body>
        {question.matrixValues.map((row, i) => (
          <Table.Row key={row.id}>
            <Table.HCell key={row.id} text={row.title} textAlign="right" />
            {question.settings.columns.map((column) => (
              <Table.Cell
                key={column.id}
                text={getValue(row, column)}
                textAlign="center"
              />
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </TableComposition>
  );
};

export default SingleMatrixAnswerTableValue;
